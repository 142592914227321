<template>
    <div class="documents">
        <label>Documents</label>
        <div class="body">
            <template v-if="!documentsToShow.length">
                <div class="none">
                    <figure>
                        <img src="/assets/images/doc-icon.svg" alt="empty_documents" />
                    </figure>
                    <span>No documents were added yet.</span>
                </div>
            </template>
            <template v-else>
                <div class="files">
                    <template v-for="(file,i) in documentsToShow" :key="i">
                        <div class="file">
                            <figure @click="openDoc(file)">
                                <img v-if="file.og_doc?.type.includes('image') || file.file_type?.includes('image')" :src="file.link" alt="image">
                                <img v-else class="icon" src="/assets/images/doc-icon.svg" alt="document">
                            </figure>
                            <div class="info" @click="openDoc(file)">
                                <span class="name">{{file.name}}</span>
                                <div class="extras">
                                    <span>imported at: {{file.imported_at}}</span>
                                    <span>size: {{file.size}}</span>
                                    <span v-if="file.pages">pages: {{file.pages}}</span>
                                </div>
                            </div>
                            <figure class="delete" @click="handleDeleteDoc(file)">
                                <img src="/assets/images/trash.svg" alt="document">
                            </figure>
                        </div>
                    </template>
                </div>
            </template>
            <footer>
                <label>
                    <input ref="doc" type="file" @change="importDocument">
                    <span>+ Add Document</span>
                </label>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },

    data() {
        return {
            documents: [],
            newDocuments: [],
            deletedDocuments: [],
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    computed: {
        documentsToShow() {
            const docs = this.documents
            const ids = docs.map(el => el.id)
            const names = this.newDocuments.map(el => el.name)
            this.deletedDocuments.forEach((el,i) => {
                if (ids.includes(el.id)) {
                    docs.splice(ids.indexOf(el.id), 1)
                }
                else if (names.includes(el.name)) {
                    this.newDocuments.splice(names.indexOf(el.name), 1)
                    this.deletedDocuments.splice(i, 1)
                }
            });
            const all = [...docs, ...this.newDocuments]
            return all
        }
    },

    watch: {
        documentsToShow(val) {
            this.$emit('handleData', {name: 'documents', value: val})
            this.$emit('handleData', {name: 'newDocuments', value: this.newDocuments})
            this.$emit('handleData', {name: 'deletedDocuments', value: this.deletedDocuments})
        },

        data: {
            immediate: true,
            handler(val) {
                if (val && val.length) this.documents = val
            }
        }
    },

    methods: {
        importDocument(e) {
            const file = e.target.files[0];
            let filePages = 0;
            const today = new Date();
            const date =
                today.getDate() +
                "/" +
                (today.getMonth() + 1) +
                "/" +
                today.getFullYear();

            const split = file.name.split(".")

            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onloadend = () => {
                filePages = reader.result.match(/\/Type[\s]*\/Page[^s]/g)
                ? reader.result.match(/\/Type[\s]*\/Page[^s]/g).length
                : undefined;

                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    
                    this.newDocuments.push({
                        name: file.name,
                        pages: filePages,
                        size: this.bytesToSize(file.size),
                        extension: split[split.length - 1].toLowerCase(),
                        imported_at: date,
                        link: reader.result,
                        edit: false,
                        og_doc: file,
                    });

                    this.$refs.doc.value = "";
                };
            };
        },

        handleDeleteDoc(file) {
            this.deletedDocuments.push(file)
        },

        bytesToSize(bytes) {
            const sizes = ["bytes", "kb", "mb", "gb", "tb"];
            if (bytes == 0) return "0 Byte";
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
        },

        openDoc(file) {
            if (file.file) {
             window.open(this.imgURL + file.file, '_blank')
            }
            else if (file.link) {
                let fileWindow = window.open("");
                fileWindow.document.write(`
                    <html>
                    <head>
                    <title>
                        ${file.name}
                    </title>
                    </head>
                    <body style="margin: 0 auto;">
                        <embed width='100%' height='100%' src="${file.link}" />
                    </body>
                    </html>`
                );
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .documents {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 0.2rem;

        label {
            font-weight: 600;
            font-size: 1rem;
        }

        .body {
            width: 100%;
            height: 15rem;
            border: 1px solid #d4dee7;
            position: relative;

            .none {
                width: 100%;
                height: calc(100% - 2rem);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                opacity: 0.4;

                figure {
                    width: 50px;
                    height: 50px;
                    border: solid 1px rgb(133, 131, 131);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 40%;
                    }
                }
            }

            .files {
                width: 100%;
                height: calc(100% - 2rem);
                display: flex;
                flex-direction: column;
                padding-inline: 10px;
                overflow: auto;

                .file {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding-block: 10px;
                    border-bottom: solid 1px $grey;
                    
                    figure {
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 2rem;
                            height: 2rem;
                            object-fit: cover;

                            &.icon {
                                object-fit: contain;
                            }
                        }
                    }

                    .info {
                        width: calc(100% - 3rem - 2rem);
                        display: flex;
                        flex-direction: column;
                        gap: 0.2rem;

                        .name {
                            width: 90%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 500;
                        }

                        .extras {
                            width: 100%;
                            text-transform: capitalize;
                            display: flex;
                            flex-wrap: wrap;
                            row-gap: 0.2rem;
                            column-gap: 0.4rem;
                            font-size: 0.7rem;
                        }
                    }
                        

                    .delete {
                        width: 1rem;
                        height: 1rem;
                        cursor: pointer;
                        img {
                            width: 100%;
                            height: 100%;
                            -webkit-user-drag: none;
                            object-fit: contain;
                        }
                    }
                }
            }

            footer {
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 2rem;
                background: var(--primary-color);
                color: white;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background: var(--primary-hover);
                }

                label {
                    cursor: pointer;
                    font-weight: inherit;
                    font-size: inherit;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    input {
                        display: none;
                    }
                    
                }
            }
        }
    }
</style>