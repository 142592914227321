<template>
    <div class="item" :class="{textarea: data.type === 'textarea'}">
        <label>{{data.label}}</label>
        <input v-if="data.type !== 'textarea'" v-model="value" :type="data.type" :placeholder="data.placeholder" :required="data.required">
        <textarea v-else v-model="value" :placeholder="data.placeholder" :required="data.required" />
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },

        values: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            value: undefined
        }
    },

    watch: {
        value(val) {
            this.$emit('handleData', {name: this.data.name, value: val})
        },

        values: {
            immediate: true,
            handler(val) {
                if (val && Object.keys(val).length) this.value = val[this.data.name]
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .item {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        height: 3.4rem;

        &.textarea {
            height: 12rem;
        }

        label {
            font-weight: 600;
            font-size: 1rem;
        }

        input, textarea {
            border: 1px solid #d4dee7;
            width: 100%;
            padding: 5px 10px;

            &:focus {
                outline: none;
            }
        }

        textarea {
            resize: none;
            height: 10.9rem;
        }
    }
</style>