<template>
    <div class="image-hotspot">
        <label>Hotspot Tooltips</label>
        <div class="body" :class="{fit: Object.keys(image).length}">
            <div class="image">
                <div v-if="!Object.keys(image).length" class="none" @mouseenter="hovering = true" @mouseleave="hovering = false">
                    <label>
                        <input type="file" accept="image/png, image/jpeg" @change="importImage">
                        <figure>
                            <img src="/assets/images/img-icon.svg" alt="image_icon">
                        </figure>
                        <span>Click anywhere here to upload your image</span>
                    </label>
                </div>
                <template v-else>
                    <div class="data">
                        <figure @click="createHotspot">
                            <img :src="image.link" alt="image" ref="hotspotImage">
                        </figure>
                        <span class="trash" @click="removeImage">
                            <img src="/assets/images/trash.svg" alt="trash">
                        </span>
                        <template
                            v-for="(marker, index) in hotspots"
                            :key="index"
                            >
                            <span
                                class="hotspot-marker"
                                :id="marker.number"
                                :style="{
                                    left: marker.position ? marker.position.left + 'px' : '0px',
                                    top: marker.position ? marker.position.top + 'px' : '0px',
                                    background: marker.color,
                                }"
                                @mousedown="drag = true"
                                @mousemove="drag ? handleMouseDrag(index, $event) : ''"
                                @mouseup="drag = false"
                                :title="marker.description"
                            >
                                {{ valToLetter(marker) }}
                            </span>
                            <div
                                class="hotspot-name"
                                v-if="marker.description === '' && index === hotspots.length - 1"
                                :style="{left: marker.position.left - 100 + 15 + 'px', top: marker.position.top - 40 + 'px'}"
                            >
                                <input type="text" v-model="hotspotName" placeholder="Hotspot Name" />
                                <span class="submit-name" @click="saveName(index)" > ✓ </span>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div v-if="Object.keys(image).length && hotspots.length" class="hostpots" :class="{info: openHotspots}">
                <div class="title" @click="openHotspots = !openHotspots">
                    <span>Edit Hotspots</span>
                    <span class="arrow" :class="{close: openHotspots}" />
                </div>
                <div class="hotspots-info">
                    <template v-for="(hotspot, i) in hotspots" :key="i">
                        <Hotspot :data="hotspot" @removeHotspot="removeHotspot(i)" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Hotspot from './Hotspot.vue'

export default {
    components: {
        Hotspot
    },

    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            image: {},
            hovering: false,
            hotspots: [],
            openHotspots: false,
            drag: false,
            hotspotName: undefined,
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    watch: {
        image(val) {
            if (Object.keys(val).length) {
                const toSend = val
                val.mime = val.type
                this.$emit('handleData', {name: 'image_file', value: toSend})
                return
            }
            this.$emit('handleData', {name: 'image_file', value: val})
        },

        hotspots: {
            deep: true,
            handler(val) {
                this.$emit('handleData', {name: 'hotspots', value: val})
            }
        },

        data: {
            immediate: true,
            handler(val) {
                if (val && Object.keys(val).length && Object.keys(val.image).length) {
                    this.image = {
                        link: this.imgURL + val.image.image,
                        og_file: val.image
                    }
                    this.hotspots = val.hotspots.map(el => {
                        if (typeof el.position === 'string') el.position = JSON.parse(el.position)
                        return el
                    })
                    
                }
            }
        }
    },

    methods: {
        importImage(e) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.image = {
                    link: reader.result,
                    og_file: file,
                };
            };
        },

        createHotspot(mouse) {
            const htsp = document.querySelectorAll(".hotspot-marker");
            const canvas = this.$refs.hotspotImage;

            const rect = canvas.getBoundingClientRect();
            const mouseXPos = mouse.x - rect.left - 15;
            const mouseYPos = mouse.y - rect.top - 15;
            let markerName = "";

            const hsId = htsp ? htsp.length + 1 : "1";

            this.hotspots.push({
                number: hsId,
                position: {
                    left: mouseXPos,
                    top: mouseYPos,
                    relativeTo: {
                        height: rect.height,
                        width: rect.width,
                    },
                },
                description: markerName,
                color: "var(--primary-color)",
                edit: false,
            });
        },

        handleMouseDrag(id, mouse) {
            const canvas = this.$refs.hotspotImage;
            const rect = canvas.getBoundingClientRect();
            const mouseXPos = mouse.x - rect.left - 15;
            const mouseYPos = mouse.y - rect.top - 15;

            if (this.hotspots[id].position) {
                this.hotspots[id].position.left = mouseXPos;
                this.hotspots[id].position.top = mouseYPos;
                this.hotspots[id].position.relativeTo.height = rect.height;
                this.hotspots[id].position.relativeTo.width = rect.width;
            } else {
                this.hotspots[id].position = {
                    left: mouseXPos,
                    top: mouseYPos,
                    relativeTo: {
                        height: rect.height,
                        width: rect.width,
                    },
                };
            }
        },

        saveName(index) {
            this.hotspots[index].description = this.hotspotName
            this.hotspotName = undefined
        },

        valToLetter(val) {
            const toChange = val.number ? val.number : val.sequence
            if(toChange) return (Number(toChange) + 9).toString(36).toUpperCase()
            return 'A'
        },

        removeHotspot(index) {
            this.hotspots.splice(index, 1)
        },

        removeImage() {
            this.image = {}
            this.hotspots = []
        }
    }
}
</script>

<style lang="scss" scoped>
    .image-hotspot {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.2rem;

        label {
            font-weight: 600;
            font-size: 1rem;
        }

        .body {
            width: 100%;
            height: calc(30rem + 44px);
            border: 1px solid #d4dee7;
            position: relative;
            overflow: hidden;

            &.fit {
                width: fit-content;
                max-width: 100%;
            }

            .image {
                height: 100%;
                max-width: 100%;

                .none {
                    width: 100%;
                    height: 100%;
                    user-select: none;
                    opacity: 0.4;
                    position: relative;
                    transition: 0.2s;

                    &:hover {
                        opacity: 0.2;
                    }

                    label {
                        font-size: inherit;
                        font-weight: inherit;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 2rem;
                        cursor: pointer;

                        input {
                            display: none;
                        }

                        figure {
                            width: 50px;
                            height: 50px;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-user-drag: none;
                            }
                        }
                    }
                }

                .data {
                    height: 100%;
                    max-width: 100%;
                    position: relative;

                    .trash {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: white;
                        border-bottom-left-radius: 4px;
                        cursor: pointer;
                        transition: 0.2s;

                        &:hover {
                            background: rgba(255,255,255,0.9);
                        }

                        img {
                            width: 75%;
                        }
                    }

                    figure {
                        height: 100%;
                        max-width: 100%;

                        img {
                            height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                        }
                    }

                    .hotspot-marker {
                        user-select: none;
                        position: absolute;
                        color: white;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-items: center;
                    }

                    .hotspot-name {
                        position: absolute;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        background: white;
                        border-radius: 3px;

                        input {
                            width: 90%;
                            padding: 10px;
                        }

                        span {
                            width: 10%;
                            display: flex;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                }
            }

            .hostpots {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2rem;
                transition: 0.2s;

                &.info {
                    height: 15rem;
                }

                .title {
                    width: 100%;
                    height: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    color: white;
                    font-weight: 500;
                    user-select: none;
                    cursor: pointer;
                    background: var(--primary-color);
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;

                    span {
                        display: flex;
                        align-items: center;
                    }

                    .arrow {
                        width: 8px;
                        height: 8px;
                        transform: rotate(45deg);
                        border-top: solid 2px white;
                        border-left: solid 2px white;
                        margin-top: 4px;

                        &.close {
                            transform: rotate(225deg);
                            margin-top: 0;
                            margin-bottom: 4px;
                        }
                    }
                }

                .hotspots-info {
                    background: white;
                    height: calc(100% - 2rem);
                    padding: 10px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }
            }
            
        }
    }
</style>