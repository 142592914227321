<template>
    <div class="measurements">
        <label>Measurements</label>
        <div class="body">
            <template v-if="!measurements.length">
                <div class="none">
                    <figure>
                        <img src="/assets/images/measurements.svg" alt="empty_measurements" />
                    </figure>
                    <span>No measurements were added yet.</span>
                </div>
            </template>
            <template v-else>
                <div class="measurements">
                    <template v-for="(measurement,i) in measurements" :key="i">
                        <div class="measurement">
                            <figure>
                                <img class="icon" src="/assets/images/measurements.svg" alt="measurement">
                            </figure>
                            <div class="info">
                                <span class="value">{{measurement.value}} {{measurement.scale}}</span>
                                <div class="extras">
                                    <span>{{measurement.name}}</span>
                                    <span>•</span>
                                    <span>{{measurement.variable}}</span>
                                </div>
                            </div>
                            <figure class="delete" @click="handleDelete(i)">
                                <img src="/assets/images/trash.svg" alt="document">
                            </figure>
                        </div>
                    </template>
                </div>
            </template>
            <form id="measurement" @submit.prevent="handleCreateMeasurement" class="create" :class="{open: createMeasurement}">
                <h6>New Measurement</h6>
                <div class="item">
                    <label>Name</label>
                    <input v-model="newMeasurement.name" name="measurement-input" type="text" placeholder="Name" required>
                </div>
                <div class="item">
                    <label>Variable</label>
                    <select
                        v-model="newMeasurement.variable"
                        @change="handleVariable"
                        required
                    >
                        <option :value="undefined" disabled selected>Please select one</option>
                        <option v-for="(variable, i) in variables" :key="i" :value="variable.name">
                            {{ variable.name }}
                        </option>
                    </select>
                </div>
                <div class="item">
                    <label>Scale</label>
                    <select v-model="newMeasurement.scale" required>
                        <option v-if="scales.length" :value="undefined" disabled selected>Please select one</option>
                        <option v-else :value="undefined" disabled selected>Please select a variable first</option>
                        <template v-if="scales">
                            <option v-for="(scale, i) in scales" :key="i" :value="scale.symbol">
                                {{ scale.name }} ({{ scale.symbol }})
                            </option>
                        </template>
                    </select>
                </div>
                <div class="item" >
                    <label>Value</label>
                    <input
                        v-model="newMeasurement.value"
                        type="number"
                        step="any"
                        placeholder="Insert value here"
                        required
                    />
                </div>
                <div class="item notes">
                    <label>Notes</label>
                    <label class="switch">
                        <input type="checkbox" v-model="newMeasurement.notes" :checked="newMeasurement.notes" />
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="item notes">
                    <label>Mandatory</label>
                    <label class="switch">
                        <input type="checkbox" v-model="newMeasurement.is_mandatory" :checked="newMeasurement.is_mandatory" />
                        <span class="slider round"></span>
                    </label>
                </div>
            </form>
            <footer>
                <span @click="createMeasurement = true" v-if="!createMeasurement">+ Add Measurement</span>
                <div class="create-btns" v-else>
                    <span class="back" @click="createMeasurement = false">Go Back</span>
                    <button type="submit" form="measurement">
                        <span>Create</span>
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import variables from "@/assets/json/variables.json";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },

    data() {
        return {
            measurements: [],
            createMeasurement: false,
            variables,
            scales: [],
            newMeasurement: {}
        }
    },

    watch: {
        measurements: {
            deep: true,
            handler(val){
                this.$emit('handleData', {name: 'measurements', value: val})
            }
        },

        data: {
            immediate: true,
            handler(val) {
                if (val && val.length) this.measurements = val
            }
        }
    },

    methods: {
        handleVariable() {
            const variable = this.variables.filter(el => el.name === this.newMeasurement.variable)[0]
            this.scales = variable.scales;
        },

        handleCreateMeasurement() {
            const newM = this.newMeasurement
            this.measurements.push(newM)
            this.newMeasurement = {}
            this.createMeasurement = false
        },

        handleDelete(i) {
            this.measurements.splice(i, 1)
        }
    },

    mounted() {
        this.newMeasurement.notes = true
        this.newMeasurement.is_mandatory = true
    }
}
</script>

<style lang="scss" scoped>
    .measurements {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 0.2rem;

        label {
            font-weight: 600;
            font-size: 1rem;
        }

        .body {
            width: 100%;
            height: 15rem;
            border: 1px solid #d4dee7;
            position: relative;
            overflow: hidden;

            .none {
                width: 100%;
                height: calc(100% - 2rem);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                opacity: 0.4;

                figure {
                    width: 50px;
                    height: 50px;
                    border: solid 1px rgb(133, 131, 131);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 40%;
                    }
                }
            }

            .create {
                position: absolute;
                top: 0;
                left: 0;
                height: calc(100% - 2rem);
                transform: translateY(100%);
                transition: 0.2s;
                width: 100%;
                background: white;
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                overflow: auto;

                &.open {
                    transform: translateY(0);
                }

                h6 {
                    font-weight: 600;
                    font-size: 0.9rem;
                    text-align: center;
                }

                .item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;

                    &.notes {
                        flex-direction: row;
                        align-items: center;
                        gap: 0.5rem;
                    }

                    label {
                        font-weight: 500;
                        font-size: 0.8rem;
                    }

                    input, select {
                        padding: 10px;
                        background: #f2f5f8;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .measurements {
                width: 100%;
                height: calc(100% - 2rem);
                display: flex;
                flex-direction: column;
                padding-inline: 10px;
                overflow: auto;

                .measurement {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding-block: 10px;
                    border-bottom: solid 1px $grey;
                    
                    figure {
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 2rem;
                            height: 2rem;
                            object-fit: cover;

                            &.icon {
                                object-fit: contain;
                            }
                        }
                    }

                    .info {
                        width: calc(100% - 3rem - 2rem);
                        display: flex;
                        flex-direction: column;
                        gap: 0.2rem;

                        .value {
                            width: 90%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 600;
                        }

                        .extras {
                            width: 100%;
                            text-transform: capitalize;
                            display: flex;
                            flex-wrap: wrap;
                            row-gap: 0.2rem;
                            column-gap: 0.4rem;
                            font-size: 0.7rem;
                        }
                    }
                        

                    .delete {
                        width: 1rem;
                        height: 1rem;
                        cursor: pointer;
                        img {
                            width: 100%;
                            height: 100%;
                            -webkit-user-drag: none;
                            object-fit: contain;
                        }
                    }
                }
            }

            footer {
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 2rem;
                background: var(--primary-color);
                color: white;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                overflow: hidden;

                span, button {
                    cursor: pointer;
                    user-select: none;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.2s;

                    &:hover {
                        background: var(--primary-hover);
                    }
                }

                .create-btns {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;

                    .back {
                        width: 30%;
                        background: $bar;
                        &:hover {
                            background: rgba($bar, 0.9);
                        }
                    }

                    button {
                        width: 70%;
                        background: var(--primary-color);
                        color: white;
                        font-size: initial;
                        &:hover {
                            background: var(--primary-hover);
                        }
                    }
                }
            }
        }
    }
</style>