<template>
    <div class="tools">
        <label>Tools</label>
        <div class="body">
            <template v-if="!toolsToShow.length">
                <div class="none">
                    <figure>
                        <img src="/assets/images/tools.svg" alt="empty_tools" />
                    </figure>
                    <span>No tools were added yet.</span>
                </div>
            </template>
            <template v-else>
                <div class="tools">
                    <template v-for="(tool,i) in toolsToShow" :key="i">
                        <div class="tool">
                            <figure>
                                <img v-if="tool.icon" class="icon" :src="imgURL + tool.icon" alt="tool">
                                <img v-else-if="tool.image" class="icon" :src="tool.image.link" alt="tool">
                                <img v-else class="icon" src="/assets/images/tools.svg" alt="tool">
                            </figure>
                            <div class="info">
                                <span class="value">{{tool.name}}</span>
                            </div>
                            <figure class="delete" @click="handleDelete(tool)">
                                <img src="/assets/images/trash.svg" alt="document">
                            </figure>
                        </div>
                    </template>
                </div>
            </template>
            <form id="tool" @submit.prevent="handlecreateTool" class="create" :class="{open: createTool}">
                <h6>New Tool</h6>
                <div class="create-data">
                    <div class="icon">
                        <label>
                            <input type="file" accept="image/*" @change="importImage">
                            <span v-if="!newTool.image">icon</span>
                            <img v-else :src="newTool.image.link" />
                        </label>
                    </div>
                    <div class="item">
                        <input v-model="newTool.name" name="tool-input" type="text" placeholder="Name" required>
                    </div>
                </div>
            </form>
            <footer>
                <span @click="createTool = true" v-if="!createTool">+ Add Tool</span>
                <div class="create-btns" v-else>
                    <span class="back" @click="createTool = false">Go Back</span>
                    <button type="submit" form="tool">
                        <span >Create</span>
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import variables from "@/assets/json/variables.json";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },

    data() {
        return {
            newTools: [],
            deletedTools: [],
            tools: [],
            createTool: false,
            variables,
            scales: [],
            newTool: {},
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    computed: {
        toolsToShow() {
            const tools = this.tools
            const ids = tools.map(el => el.id)
            const names = this.newTools.map(el => el.name)
            this.deletedTools.forEach((el,i) => {
                if (ids.includes(el.id)) {
                    tools.splice(ids.indexOf(el.id), 1)
                }
                else if (names.includes(el.name)) {
                    this.newTools.splice(names.indexOf(el.name), 1)
                    this.deletedTools.splice(i, 1)
                }
            });
            const all = [...tools, ...this.newTools]
            return all
        }
    },

    watch: {
        toolsToShow(val) {
            this.$emit('handleData', {name: 'tools', value: val})
            this.$emit('handleData', {name: 'newTools', value: this.newTools})
            this.$emit('handleData', {name: 'deletedTools', value: this.deletedTools})
        },

        data: {
            immediate: true,
            handler(val) {
                if (val && val.length) this.tools = val
            }
        }
    },

    methods: {
        handleVariable(e) {
            this.scales = this.variables[e.target.value].scales;
        },

        handlecreateTool() {
            const newM = this.newTool
            this.newTools.push(newM)
            this.newTool = {}
            this.createTool = false
        },

        handleDelete(item) {
            this.deletedTools.push(item)
        },

        importImage(e) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.newTool.image = {
                    link: reader.result,
                    file,
                };
            };
        },
    }
}
</script>

<style lang="scss" scoped>
    .tools {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 0.2rem;

        label {
            font-weight: 600;
            font-size: 1rem;
        }

        .body {
            width: 100%;
            height: 15rem;
            border: 1px solid #d4dee7;
            position: relative;
            overflow: hidden;

            .none {
                width: 100%;
                height: calc(100% - 2rem);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                opacity: 0.4;

                figure {
                    width: 50px;
                    height: 50px;
                    border: solid 1px rgb(133, 131, 131);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 40%;
                    }
                }
            }

            .create {
                position: absolute;
                top: 0;
                left: 0;
                height: calc(100% - 2rem);
                transform: translateY(100%);
                transition: 0.2s;
                width: 100%;
                background: white;
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                overflow: auto;

                &.open {
                    transform: translateY(0);
                }

                h6 {
                    font-weight: 600;
                    font-size: 0.9rem;
                    text-align: center;
                }

                .create-data {
                    display: flex;
                    align-items: center;
                }

                .icon {
                    width: 36px;
                    height: 36px;
                    border: solid 1px var(--primary-color);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    overflow: hidden;

                    label {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            font-weight: 400;
                            cursor: pointer;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            cursor: pointer;
                        }

                        input {
                            display: none;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }
                    }

                    
                }

                .item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;
                    width: calc(100% - 60px);
                    margin-left: 8px;

                    &.notes {
                        flex-direction: row;
                        align-items: center;
                        gap: 0.5rem;
                    }

                    label {
                        font-weight: 500;
                        font-size: 0.8rem;
                    }

                    input, select {
                        padding: 10px;
                        background: #f2f5f8;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .tools {
                width: 100%;
                height: calc(100% - 2rem);
                display: flex;
                flex-direction: column;
                padding-inline: 10px;
                overflow: auto;

                .tool {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding-block: 10px;
                    border-bottom: solid 1px $grey;
                    
                    figure {
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 2rem;
                            height: 2rem;
                            object-fit: cover;
                            -webkit-user-drag: none;

                            &.icon {
                                object-fit: contain;
                            }
                        }
                    }

                    .info {
                        width: calc(100% - 3rem - 2rem);
                        display: flex;
                        flex-direction: column;
                        gap: 0.2rem;

                        .value {
                            width: 90%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 600;
                        }

                        .extras {
                            width: 100%;
                            text-transform: capitalize;
                            display: flex;
                            flex-wrap: wrap;
                            row-gap: 0.2rem;
                            column-gap: 0.4rem;
                            font-size: 0.7rem;
                        }
                    }
                        

                    .delete {
                        width: 1rem;
                        height: 1rem;
                        cursor: pointer;
                        img {
                            width: 100%;
                            height: 100%;
                            -webkit-user-drag: none;
                            object-fit: contain;
                        }
                    }
                }
            }

            footer {
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 2rem;
                background: var(--primary-color);
                color: white;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                overflow: hidden;

                span, button {
                    cursor: pointer;
                    user-select: none;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.2s;

                    &:hover {
                        background: var(--primary-hover);
                    }
                }

                .create-btns {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;

                    .back {
                        width: 30%;
                        background: $bar;
                        &:hover {
                            background: rgba($bar, 0.9);
                        }
                    }

                    button {
                        width: 70%;
                        background: var(--primary-color);
                        color: white;
                        font-size: initial;
                        &:hover {
                            background: var(--primary-hover);
                        }
                    }
                }
            }
        }
    }
</style>