<template>
    <div class="hotspot">
            <span class="letter" :style="{borderColor: hotspot.color}">{{ valToLetter(data) }}</span>
            <input class="name" type="text" v-model="hotspot.description" />
            <input type="color" v-model="hotspot.color" />
            <span class="trash" @click="$emit('removeHotspot')">
                <img src="/assets/images/trash.svg" alt="">
            </span>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            hotspot: {}
        }
    },

    watch: {
        data: {
            immediate: true,
            handler(val) {
                console.log(val)
                const variable = val.color.replace('var(','').replace(')', '')
                const color = getComputedStyle(document.body).getPropertyValue(variable)
                this.hotspot = val
                if (color) this.hotspot.color = color.trim()
            }
        }
    },

    methods: {
        valToLetter(val) {
            const toChange = val.number ? val.number : val.sequence
            if(toChange) return (Number(toChange) + 9).toString(36).toUpperCase()
            return 'A'
        },
    }
}
</script>

<style lang="scss" scoped>
    .hotspot {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 1rem;

        .letter {
            width: 1.5rem;
            border-right: solid 2px;
            text-align: right;
            padding-right: 5px;
        }

        .name {
            width: 80%;
            padding: 5px 10px;
            border: solid 1px $grey;
        }

        .trash {
            cursor: pointer;
            width: 15px;
            img {
                width: 100%;
            }
        }
    }
</style>